/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Text } from '@theme-ui/components'

const Footer = ({showStickyFooter = false}) => {
  return (
    <Box sx={{ backgroundColor: 'footerBg', height: '40px', border: '0px solid red' }}>
      <Text></Text>
    </Box>
  )
}

export default Footer;