/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, Text } from '@theme-ui/components';
import { Link } from 'gatsby';
import { SearchInputBox } from './search';

const Logo = () => {
  return (
    <Link
      sx={{
        mx: 3,
        my: 2,
        p: 3,
        fontWeight: 'bold',
        textDecoration: 'none',
        color: 'primary',
      }}
      to="/">
      <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>greytHR Help</Text>
    </Link>
  );
};

const SearchBlock = ({ filterApps }) => {
  return (
    <Box mx={[3, 4]}>
      <SearchInputBox filterApps={filterApps} />
    </Box>
  );
};

const Header = ({ search }) => {
  const showSearch = search?.showSearch === undefined ? true : search.showSearch;

  return (
    <Flex
      sx={{
        backgroundColor: 'headerBg',
        minHeight: '50px',
        alignItems: ['left', 'center'],
        border: '0px solid red',
        justifyContent: ['normal', 'space-between'],
        flexDirection: ['column', 'row'],
      }}>
      <Flex>
        <Logo />
      </Flex>
      {showSearch ? <SearchBlock filterApps={search?.filterApps} /> : null}
    </Flex>
  );
};

export default Header;
