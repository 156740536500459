import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import ResultBox from './ResultBox';
import queryString from 'query-string';
import algoliasearch from 'algoliasearch/lite';
import { Box, Heading } from '@theme-ui/components';
import {
  InstantSearch,
  SearchBox,
  connectRefinementList,
} from 'react-instantsearch-dom';

const DEBOUNCE_TIME = 400;

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const VirtualRefinementList = connectRefinementList(() => null);

const createURL = (state) => `?${queryString.stringify(state)}`;

const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = ({ search }) => queryString.parse(search.slice(1));

const Search = ({ location, indices, filterApps = [] }) => {
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);
  const [filterValues, setFilterValues] = useState([]);

  useEffect(() => {
    const apps = state?.filterApps ? state.filterApps : filterApps;
    setFilterValues(apps);
  }, []);

  const { state } = location;

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        navigate(searchStateToUrl(location, updatedSearchState));
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}>
      <VirtualRefinementList
        attribute="appSlug"
        defaultRefinement={filterValues}
      />
      <Box my={4}>
        <Heading as="h1" mb={2} sx={{ fontSize: 2 }}>
          Search greytHR Help
        </Heading>
        <Box sx={inputStyle}>
          <SearchBox
            searchAsYouType={false}
            translations={{ placeholder: 'Search' }}
          />
        </Box>
        <ResultBox indices={indices} />
      </Box>
    </InstantSearch>
  );
};

const inputStyle = {
  display: 'inline-flex',
  border: '2px solid',
  borderColor: 'primary',
  borderRadius: '4px',
  '.ais-SearchBox-reset': {
    display: 'none',
  },
  '.ais-SearchBox-input': {
    outline: 'none',
    border: 'none',
    width: [260, 600],
    p: 2,
  },
  '.ais-SearchBox-submit': {
    backgroundColor: 'white',
    border: 'none',
    p: 2,
  },
  '.ais-SearchBox-submitIcon': {
    width: 4,
    height: 4,
  },
};

export default Search;
