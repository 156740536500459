/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Flex } from '@theme-ui/components'
// import Helmet from 'react-helmet'

import HeaderV2 from './Header'
import Footer from './Footer'

const Layout = ({ showHeader = true, showFooter = true, showStickyFooter = false, search, sx, children}) => {
    return (
      <Box sx={{ ...sx, fontFamily: 'body', border: '0px solid green' }}>
        {/* <BugHerd /> */}
        {/* this takes data from methadata.json which is created while build time and adds meta tags to header */}
        {/* <Helmet>
          {metaData
            ? Object.keys(metaData).map((key, index) => <meta name={key} content={metaData[key]} key={index} />)
            : null}
        </Helmet> */}

        <Flex
          sx={{
            flexDirection: 'column',
            minHeight: '100vh',
          }}
          as="div"
        >
          {showHeader ? <HeaderV2 search={search}/> : null}
          <div sx={{ flex: '1 1 auto', m: 0 }}>
            {children}
          </div>
          {showFooter ? <Footer showStickyFooter={showStickyFooter} /> : null}
        </Flex>
      </Box>
    )
}

export default Layout
