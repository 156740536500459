import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { Input, Box, Flex } from '@theme-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchInputBox = ({ styles, filterApps }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const searchURL = `/search/?page=1&query=`;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm !== '') {
      navigate(searchURL + searchTerm, { state: { filterApps: filterApps } });
    }
  };

  const sx = { 
    flexDirection: 'row', 
    alignItems: 'center', 
    borderWidth: 1, 
    borderStyle: 'solid', 
    borderColor: '#FEFEFE', 
    borderRadius: '6px',  
    backgroundColor: 'white', 
    boxShadow: '1px 2px 5px 0px #CECECE',
    color: 'gray', 
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  }

  return (
    <Flex as="form" onSubmit={handleSubmit} sx={sx} >
      <Input
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ flexGrow: 1, justifySelf: 'stretch', borderWidth: 0, outline: 'none'}}
      />
      <Box sx={{ p: 2,  }}> 
        <FontAwesomeIcon icon={faSearch} sx={{ fontSize: '20px', color: 'white' }} />
      </Box>
    </Flex>
  );
};

// const formStyle = {
//   my: 2,
//   display: 'inline-flex',
//   justifyContent: 'flex-start',
//   '.iconContainer': {
//     textAlign: 'center',
//     p: 2,
//     px: 3,
//   },
//   '.inputBox': {
//     width: ['100%', '100%', '40vw', '20vw'],
//     m: 0,
//     py: 1,
//     fontSize: 1,
//     '&:focus': {
//       outlineColor: 'primary',
//     },
//     bg: 'white',
//     border: '1px solid',
//     borderColor: 'primary',
//   }
// };

export default SearchInputBox;
