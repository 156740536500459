import React from 'react'
import { Box } from '@theme-ui/components'
import PropTypes from 'prop-types'

const Container = React.forwardRef((props, ref) => {
  const regular = ['100%', '750px', '970px', '1170px', '1500px']
  const pillar = ['100%', '750px', '750px', '750px']
  const maxWidth = props.variant === 'pillar' ? pillar : regular
  return (
    <Box
      ref={ref}
      variant="container"
      {...props}
      __themeKey="layout"
      __css={{
        width: '100%',
        maxWidth,
        mx: 'auto',
        my: 0,
        px: '10px',
      }}
    />
  )
})

Container.displayName = 'Container'

Container.propTypes = {
  variant: PropTypes.string,
}

Container.defaultProps = {
  variant: 'regular',
}

export default Container
