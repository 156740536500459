import React from 'react';
import * as hitComps from './hitComps';
import { Box, Flex } from '@theme-ui/components';
import { Hits, connectStateResults, Pagination } from 'react-instantsearch-dom';

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? children : `No results for '${state.query}'`
);

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
);

const ResultHeader = ({ title }) => (
  <Flex sx={resultHeaderStyle}>
    <Box>
      <Box sx={{ fontWeight: 'bold' }}>{title}</Box>
      <Stats />
    </Box>
    <Box sx={paginationStyle}>
      <Pagination />
    </Box>
  </Flex>
);

const ResultBox = ({ indices }) => (
  <>
    {indices.map(({ title, hitComp }, index) => (
      <Box key={index}>
        <ResultHeader title={title} />
        <Box sx={hitListStyle}>
          <Results>
            <Hits hitComponent={hitComps[hitComp]()} />
          </Results>
        </Box>
      </Box>
    ))}
  </>
);

const resultHeaderStyle = {
  my: 4,
  justifyContent: 'space-between',
  borderBottom: '1px solid lightgray',
};

const hitListStyle = {
  '.ais-Hits-list': {
    listStyleType: 'none',
    pl: 1,
  },
  '.ais-Hits-item': {
    mb: 4,
  },
};

const paginationStyle = {
  '.ais-Pagination-list': {
    listStyleType: 'none',
    overflow: 'hidden',
  },
  '.ais-Pagination-item': {
    float: 'left',
    p: 1,
    px: 2,
    mx: 1,
    minWidth: '32px',
    bg: 'lightgray',
    borderRadius: 3,
    textAlign: 'center'
  },
  '.ais-Pagination-item > a': {
    textDecoration: 'none',
    color: 'primary',
  },
  '.ais-Pagination-item--selected': {
    backgroundColor: 'primary',
  },
  '.ais-Pagination-item--selected > a': {
    color: 'white',
  },
};

export default ResultBox;
