import React from 'react';
import { Link } from 'gatsby';
import { Snippet } from 'react-instantsearch-dom';
import { Box, Heading } from '@theme-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

const getLink = (type, slug, appSlug) => {
  if (type === 'article') {
    const useAppSlug = (process.env.GATSBY_USE_APP_SLUG || 'TRUE').toUpperCase() === "TRUE"
    return useAppSlug ? `/${appSlug}/answers/${slug}/` : `/answers/${slug}/`;
  } else if (type === 'topic') {
    return `/${appSlug}/${slug}/`;
  } else {
    return `/${slug}/`;
  }
};

export const PageHit = () => ({ hit }) => {
  const { type, slug, appSlug } = hit;

  return (
    <Box sx={{'a': {textDecorationColor: 'black'}}}>
      <Link to={getLink(type, slug, appSlug)}>
        <Heading variant="lightHeading" sx={{fontSize: 2, color: 'black'}}>
          <Snippet attribute="name" hit={hit} tagName="mark" />
        </Heading>
      </Link>
      <Box sx={{ mt: 2, fontSize: '14px', color: 'muted' }}>
        <FontAwesomeIcon icon={faTag} style={{fontSize: '14px'}}/> {type}
      </Box>
      <Snippet attribute="content" hit={hit} tagName="mark" />
    </Box>
  );
};
